<template>
  <section>
    <b-card>
      <b-breadcrumb>
        <div class="d-flex align-items-center">
          <span class="text-primary">Report </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Report Accessi</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-breadcrumb>
    </b-card>

    <b-row>
      <b-col md="6">
        <b-card>
          <b-avatar variant="light-primary" size="50">
            <span class="d-flex align-items-center">
              <feather-icon icon="CalendarIcon" size="20" />
            </span>
          </b-avatar>

          <div class="d-flex align-items-center justify-content-between mt-2">
            <div>
              <h2>Calendario accessi</h2>
              <span>Visualizza il calendario degli accessi</span>
            </div>

            <div>
              <b-button variant="primary-light">
                Accedi al calendario
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card>
          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" size="50">
              <span class="d-flex align-items-center">
                <feather-icon icon="TrendingUpIcon" size="20" />
              </span>
            </b-avatar>
            <span class="ml-1 text-primary h2"> 327 </span>
          </div>

          <div class="mt-2">
            <h2>Accessi Settimanali</h2>
            <span>Gli accessi di questa settimana</span>
          </div>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card>
          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" size="50">
              <span class="d-flex align-items-center">
                <feather-icon icon="TrendingUpIcon" size="20" />
              </span>
            </b-avatar>
            <span class="ml-1 text-primary h2"> 1084 </span>
          </div>

          <div class="mt-2">
            <h2>Accessi Mensili</h2>
            <span>Gli accessi di questo mese</span>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-card>
          <h3>Auto più consultate</h3>

          <chartjs-component-doughnut-chart :data="doughnutChart.data" :options="doughnutChart.options"/>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card>
          <h3>Ultimi 10 accessi</h3>
        </b-card>
      </b-col>

      <b-col md="3">
        <b-card>
          <h3>Questa settimana</h3>

          <app-timeline class="mt-3">
            <app-timeline-item
              v-for="i in 7"
              :key="i"
              title="Lunedì"
              subtitle="320 Accessi"
              variant="primary"
            />
          </app-timeline>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAvatar,
  BButton,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
} from "bootstrap-vue";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

import ChartjsComponentDoughnutChart from "@/components/charts/ChartjsComponentDoughnutChart.vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    ChartjsComponentDoughnutChart
  },

  data: () => ({
    doughnutChart: {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      cutoutPercentage: 0,
      legend: { display: true },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
      },
    },
    data: {
      datasets: [
        {
          labels: ['Marca auto 1', 'Marca auto 2', 'Marca auto 3'],
          data: [890, 590, 290],
          backgroundColor: ['#377DFF', '#5E5873', '#2333B6'],
          borderWidth: 5,
          pointStyle: 'rectRounded',
        },
      ],
    },
  },
  })
};
</script>
